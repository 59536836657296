import React from "react"
import { Button } from '@fluentui/react-components';
import './AteButtonRaised.scss';

export interface IAteButtonRaised {
    icon?: JSX.Element,
    content?: string;
    isActive?: boolean;
    iconPosition?: 'before' | 'after';
    onClick?(): any;
}

export default function AteButtonRaised(props: IAteButtonRaised) {
    return (
        <div
            data-testid="button-raised-wrapper"
            className={`button-raised`}>
            <Button
                data-testid="button-raised"
                className={'ui-button'}
                icon={props.icon}
                iconPosition={props.iconPosition}
                onClick={props.onClick}
                appearance={props.isActive ? "primary" : "transparent"}
            >
                {props.content}
            </Button>
        </div>
    )
}
