import { hAlignType, IWidgetTranslationLabelSettings, vAlignType } from "interfaces/commons.interface";

export interface IAteCustomBoxBackgroundSettings {
    color?: string,
    image?: IAteCustomBoxBackgroundImageSettings
}

export interface IAteCustomBoxBackgroundImageSettings {
    url?: string,
    keep_aspect_ratio: boolean,
}

export interface IAteCustomContentBoxWidget {
    title?: IWidgetTranslationLabelSettings,
    description?: IWidgetTranslationLabelSettings,
    // TODO make this optional
    text_color: string,
    background: IAteCustomBoxBackgroundSettings,
    destination_url?: IAteCustomBoxBaseURLSettings,
    // TODO make this optional
    vertical_align: vAlignType,
    // TODO make this optional
    horizontal_align: hAlignType,
    min_height?: number
}

export interface IAteCustomBoxBaseURLSettings {
    enabled: boolean,
    settings?: IAteCustomBoxURLSettings,
}

export interface IAteCustomBoxURLSettings {
    url: string,
    type?: AteCustomContentBoxUrlElementType,
    button_settings?: IAteCustomBoxButtonSettings
}

export enum AteCustomContentBoxUrlElementType {
    BUTTON = 'button',
    BACKGROUND = 'background',
}

export interface IAteCustomBoxButtonSettings {
    text: string,
    color: string,
    background_color: string,
}
