import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import { Card, Divider, Badge } from "@fluentui/react-components";
import { IAteCard } from 'components/shared/cards/interfaces/card.interface';
import './AteCard.scss';
import React from 'react';
import { AteIcon } from 'components/shared/icon/AteIcon';
import { IconSizeEnum } from 'components/shared/icon/helpers/icon-size';
import { IconColorEnum } from 'components/shared/icon/helpers/icon-color';
import { MSTeamsThemes } from 'components/App';
import { useSelector } from 'react-redux';
import { getMsTeamsThemeSelected } from 'store/app/app.selectors';

export default function AteCard(props: IAteCard) {
    const themeSelected = useSelector(getMsTeamsThemeSelected);

    const getBackgroundImageCssRule = () => {
        return { '--course-background-image': `url('${props.imageUrl}')` } as React.CSSProperties;
    };

    const handleOnClick = (): void => {
        if (!props.locked && props.onClick) {
            props.onClick();
        }
    }

    return (
        <Card
            data-testid="card-item"
            className={`ate-card ${props.stacked ? 'stacked' : ''} ${props.locked ? 'locked' : ''}`}
            onClick={handleOnClick}>
            <Flex column>
                <Flex vAlign={'center'}>
                    <Flex className={'card-preview-container'}>
                        <Flex
                            style={
                                {
                                    ...getBackgroundImageCssRule(),
                                }
                            }
                            className={'card-image'}
                            vAlign={'end'}
                        />
                        {props.leftLabel && !props.locked &&
                            <Badge appearance="filled"
                                   shape="rounded"
                                   color={props.leftLabelColor}
                                   className={'card-left-label'}>
                                <StyledText size={'smaller'}>
                                    {props.leftLabel}
                                </StyledText>
                            </Badge>
                        }
                        {props.locked &&
                            <Flex vAlign={'center'}
                                hAlign={'center'}
                                className={'locked'}>
                                <div className="container">
                                    <AteIcon
                                        size={IconSizeEnum.MD}
                                        name={'locked'}
                                        color={IconColorEnum.NEGATIVE}
                                    />
                                </div>
                            </Flex>
                        }
                        {props.rightLabel && !props.locked &&
                            <Badge appearance="filled"
                                   shape="rounded"
                                   color={props.rightLabelColor}
                                   className={'card-right-label'}>
                                <StyledText size={'smaller'}>
                                    {props.rightLabel}
                                </StyledText>
                            </Badge>
                        }
                    </Flex>
                </Flex>
                <Flex className={'card-body'}
                    column
                    space={'between'}>
                    <StyledText className={'card-title'}
                        size={'medium'}
                        weight={'semibold'} >
                        {props.title}
                    </StyledText>
                    <Flex column>
                        {!!props.subtitle &&
                            <StyledText className={'card-available'}
                                size={'small'}
                                weight={'semilight'} >
                                {props.subtitle}
                            </StyledText>
                        }
                        <Flex space={'between'}>
                            <Flex>
                                <StyledText
                                    size={'small'}
                                    weight={'semibold'}>
                                    {props.detailsFirstItem}
                                </StyledText>
                                {props.detailsSecondItem &&
                                    <>
                                        <Divider className={'card-details-divider'}
                                            vertical
                                            color={'grey'} />
                                        <StyledText
                                            size={'small'}
                                            weight={'semibold'} >
                                        {props.detailsSecondItem}
                                        </StyledText>
                                    </>
                                }
                            </Flex>
                            {!!props.rating &&
                                <Flex className={'card-rating'}
                                    vAlign={'center'}>
                                    <AteIcon
                                        size={IconSizeEnum.XS}
                                        name={'star-filled'}
                                        color={IconColorEnum.SUCCESS}
                                    />
                                    <StyledText
                                        className={'number'}
                                        size={'small'}
                                        weight={'bold'} >
                                        {props.rating}
                                    </StyledText>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                </Flex>
                <Divider />
                <Flex className={'card-footer'}
                    space={'between'}
                    vAlign={'center'}>
                    <Flex vAlign={'center'}>
                        {props.footerIcon &&
                            <AteIcon
                                size={IconSizeEnum.XS}
                                name={props.footerIcon}
                                color={themeSelected === MSTeamsThemes.DEFAULT ? IconColorEnum.DARK : IconColorEnum.NEGATIVE}
                            />
                        }
                        <StyledText className={'card-footer-label'} >
                            {props.footerLabel}
                        </StyledText>
                    </Flex>
                    {!props.locked && props.footerMenu}
                </Flex>
            </Flex>
        </Card>
    );
}
