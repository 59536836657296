import React, { useEffect, useState } from 'react';
import AteCustomContentBox from 'components/widgets/customContentBox/AteCustomContentBox';
import { AteMyCoursesAndLp } from 'components/widgets/myCoursesAndLp/AteMyCoursesAndLp';
import { IDashboardLayoutRow, IWidget, WidgetTypeEnum } from 'components/pages/dashboard/interfaces/layout.response';
import { IAteMyCoursesAndLpWidget } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import { IAteSingleCoursePlayerPreviewWidget } from 'components/widgets/coursePlayer/interface/coursePlayer.interface';
import AteSingleCoursePlayerPreview
    from 'components/widgets/coursePlayer/coursePlayerPreview/AteSingleCoursePlayerPreview';
import './DashboardPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetBreadcrumb } from 'store/breadcrumb/breadcrumb.slice';
import { IAteCustomContentBoxWidget } from 'components/widgets/customContentBox/interface/customContentBox.interface';
import { getCourse, getTenantConfig } from 'api/BackendCalls';
import { getBaseUrl, getDcbAccessToken } from 'store/auth/auth.selectors';
import { getTenantId } from 'store/msContext/msContext.selector';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { Spinner } from '@fluentui/react-components';
import { AteBlankSlate } from 'components/shared/blankSlate/AteBlankSlate';
import { WidgetTranslationTypeEnum } from 'interfaces/commons.interface';
import { LGZone, XLZone } from 'helpers/viewportBreakpoint.helpers';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ICourseInfo } from 'interfaces/course.interface';
import { isCourseAccessible } from 'helpers/course.helpers';
import _ from 'lodash';
import { IAteStaticCourseListWidget } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';
import AteStaticCourseList from 'components/widgets/staticCourseList/AteStaticCourseList';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { resetMyCoursesAndLpWidgetSettings, updateMyCoursesAndLearnignPlanUUIDActivated } from 'store/app/app.slice';
import { v4 as uuidv4 } from 'uuid';

export default function DashboardPage() {
    const dispatch = useDispatch();
    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const tenantId = useSelector(getTenantId);
    const [dashboardRows, setDashboardRows] = useState<IDashboardLayoutRow[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDashboardNotEmpty, setIsDashboardNotEmpty] = useState<boolean>(false);

    const isLGZone = LGZone();
    const isXLZone = XLZone();

    useEffect(() => {
        dispatch(resetBreadcrumb());
        resetMyCoursesAndLearningPlanState();
        getDashboardLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDashboardLayout = () => {
        getTenantConfig({ accessToken, baseUrl }, tenantId)
            .pipe(
                mergeMap(async res => {
                    const rows = res.rows;
                    const isOK = await checkDashboardStatus(rows);
                    setIsDashboardNotEmpty(isOK);
                    return rows;
                })
            )
            .subscribe(res => {
                setDashboardRows(res);
                setIsLoading(false);
            })
    }

    const resetMyCoursesAndLearningPlanState = () => {
        dispatch(updateMyCoursesAndLearnignPlanUUIDActivated(undefined));
        dispatch(resetMyCoursesAndLpWidgetSettings([]));
    }

    const getWidget = (widget: IWidget, isWidgetFullRow: boolean) => {
        switch (widget.type) {
            case WidgetTypeEnum.CUSTOM_CONTENT_BOX: {
                return (
                    <AteCustomContentBox
                        {...(widget.settings as IAteCustomContentBoxWidget)}
                    />
                );
            }
            case WidgetTypeEnum.SINGLE_COURSE_PLAYER: {
                return (
                    <AteSingleCoursePlayerPreview
                        {...(widget.settings as IAteSingleCoursePlayerPreviewWidget)}
                    />
                );
            }
            case WidgetTypeEnum.MY_COURSES_AND_LEARNING_PLANS: {
                const settings = widget.settings as IAteMyCoursesAndLpWidget;
                return (
                    <AteMyCoursesAndLp
                        {...{
                            ...settings,
                            isDashboardPage: true,
                            hideFiltersLabel: !isWidgetFullRow
                        }}
                    />
                );
            }
            case WidgetTypeEnum.STATIC_COURSE_LIST: {
                return (
                    <AteStaticCourseList
                        {...(widget.settings as IAteStaticCourseListWidget)}
                    />
                )
            }
        }
    };

    const checkDashboardStatus = (rows: IDashboardLayoutRow[]): Promise<boolean> => {
        return new Promise(async resolve => {
            let totalWidgets = 0;
            let hasNotSCPWidgets = false;
            const coursesObs: Observable<ICourseInfo | null>[] = [];

            for (let iRow = 0; iRow < rows.length; iRow++) {
                const columns = rows[iRow].columns;
                for (let iColumn = 0; iColumn < columns.length; iColumn++) {
                    const notPlaceholderWidgets = columns[iColumn].widgets.filter(c => c.type !== WidgetTypeEnum.PLACEHOLDER);
                    totalWidgets += notPlaceholderWidgets.length;

                    //Set a UUID to MYCLP Widget so them can be managed from reducers
                    columns[iColumn].widgets.forEach(widget => {
                        if (widget.type === WidgetTypeEnum.MY_COURSES_AND_LEARNING_PLANS) {
                            (widget.settings as IAteMyCoursesAndLpWidget).UUID = uuidv4();
                        }
                    })

                    //Check if TotalWidgets is greater then 0 and has not already found a different widget than SINGLE_COURSE_PLAYER
                    if (totalWidgets && !hasNotSCPWidgets) {
                        hasNotSCPWidgets = !!notPlaceholderWidgets.find(widget => widget.type !== WidgetTypeEnum.SINGLE_COURSE_PLAYER);

                        const obs = notPlaceholderWidgets.map(widget => {
                            if (widget.type === WidgetTypeEnum.SINGLE_COURSE_PLAYER) {
                                const widgetSettings = widget.settings as IAteSingleCoursePlayerPreviewWidget;
                                return getCourse({ accessToken, baseUrl }, widgetSettings.course_id)
                                    .pipe(
                                        catchError(() => of(null))
                                    )
                            }

                            return of(null);
                        });

                        //Create getCourse() Observables to check visibility of Course
                        coursesObs.push(...obs)
                    }
                }
            }

            //Dashboard is Empty
            if (!totalWidgets) {
                return resolve(false);
            }

            //If Dashboard has at least one different widget than SINGLE_COURSE_PLAYER resolves the promise
            if (hasNotSCPWidgets) {
                return resolve(true);
            }

            //If Dashboard has only SINGLE_COURSE_PLAYER Widgets check that at least one Course is usable by User
            const hasDashboardVisibleCourses = await forkJoin(coursesObs)
                .pipe(
                    map(courses => courses.filter(course => !!course && isCourseAccessible(course)))
                )
                .toPromise();

            return resolve(!_.isEmpty(hasDashboardVisibleCourses));
        })
    };

    return (
        <div
            className={'dashboard-page'}>
            {
                isLoading ?
                    <div className="spinner">
                        <Spinner />
                    </div>
                    :
                    isDashboardNotEmpty ?
                        dashboardRows.map((row, index) =>
                            <div
                                key={index}
                                className={`ui-row dashboard-row ${!isLGZone && !isXLZone ? 'mobile' : ''}`}>
                                {
                                    row.columns.map((column, columnIndex) =>
                                        <div
                                            key={columnIndex}
                                            className={`column ui-col-24 ui-col-lg-${column.size.desktop * 2}`}>
                                            {
                                                column.widgets.map((widget, widgetIndex) =>
                                                    <div
                                                        key={widgetIndex}
                                                        className={`widget`}>
                                                        {getWidget(widget, row.columns.length === 1)}
                                                    </div>
                                                )
                                            }
                                        </div>,
                                    )
                                }
                            </div>
                        ) :
                        <Flex
                            data-testid="dashboard-blankslate-wrapper"
                            hAlign="center"
                            vAlign="center"
                            className={'empty-dashboard-blankslate'}>
                            <AteBlankSlate
                                title={{
                                    type: WidgetTranslationTypeEnum.SINGLE,
                                    value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateNothingHereYet),
                                }}
                                subtitle={{
                                    type: WidgetTranslationTypeEnum.SINGLE,
                                    value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlatelooksLikeYourAdminIsStillSettingUpYourDashboard),
                                }}
                                illustrationName='take-a-break'
                            />
                        </Flex>
            }
        </div >
    );
}
