import React from 'react';
import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import './AteCustomContentBox.scss';
import { useSelector } from 'react-redux';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import { HorizontalAlignValueToFlexEnum, VerticalAlignValueToFlexEnum } from 'interfaces/commons.interface';
import { AteCustomContentBoxUrlElementType, IAteCustomContentBoxWidget } from './interface/customContentBox.interface';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';
import { app } from '@microsoft/teams-js';
import { getMsTeamsThemeSelected } from '../../../store/app/app.selectors';
import { MSTeamsThemes } from '../../App';
import { Button } from '@fluentui/react-components';
import { adjustColor } from '../../../helpers/colors.helpers';
import classNames from 'classnames';

export default function AteCustomContentBox(props: IAteCustomContentBoxWidget) {
    const themeSelected = useSelector(getMsTeamsThemeSelected);
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);

    const onClickCustomContentBox = () => {
        app.openLink(props.destination_url?.settings?.url as string)
    }

    return (
        <Flex
            style={{
                    minHeight: !props.background.image?.keep_aspect_ratio ? (props.min_height === 0 ? 64 : props.min_height) : '',
                    color: props.text_color,
                    backgroundColor: props.background.color,
                    backgroundImage: !props.background.image?.keep_aspect_ratio ? `url("${props.background.image?.url}")` : '',
                    cursor: props.destination_url?.enabled && props.destination_url.settings?.type === AteCustomContentBoxUrlElementType.BACKGROUND ? 'pointer' : '',
                    justifyContent: VerticalAlignValueToFlexEnum[props.vertical_align],
                }}
            data-testid="custom-content-box"
            className={`custom-content-box`}
            onClick={props.destination_url?.enabled && props.destination_url.settings?.type === AteCustomContentBoxUrlElementType.BACKGROUND ? onClickCustomContentBox : undefined}
        >
            <Flex
                className="content-container"
                column
                style={{
                    alignItems: HorizontalAlignValueToFlexEnum[props.horizontal_align],
                    justifyContent: !props.title &&
                                    !props.description &&
                                     props.destination_url?.settings?.type === AteCustomContentBoxUrlElementType.BUTTON &&
                                     props.vertical_align === 'top-bottom' ?
                                    'flex-end' :
                                     VerticalAlignValueToFlexEnum[props.vertical_align],
                }}>
            {
                props.title && !props.description ?
                    <Flex
                        className="title-container">
                        <StyledText
                            size={'large500'}
                            weight={'semibold'}
                            style={
                                {
                                    textAlign: props.horizontal_align,
                                }
                            }
                        >
                            {getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex> : ''
            }
            {
                props.description && !props.title ?
                    <Flex
                        className="description-container">
                        <StyledText
                            size={'small'}
                            style={
                                {
                                    textAlign: props.horizontal_align,
                                }
                            }
                        >
                            {getWidgetTranslatedLabel(props.description, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex> : ''
            }
            {
                props.title && props.description ?
                    <Flex
                        className="title-description-wrapper"
                        column
                        style={
                            {
                                textAlign: props.horizontal_align,
                            }
                        }>
                        <StyledText
                            size={'large500'}
                            weight={'semibold'}
                        >
                            {getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                        <StyledText
                            size={'small'}
                            className="ellipsis"
                        >
                            {getWidgetTranslatedLabel(props.description, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex> : ''
            }
            {
                props.destination_url?.settings?.type === AteCustomContentBoxUrlElementType.BUTTON ?
                    <Button
                        data-testid="custom-button"
                        onClick={() => onClickCustomContentBox()}
                        appearance="primary"
                        className={
                            classNames({
                                'custom-button': true,
                                'button-default': themeSelected === MSTeamsThemes.DEFAULT,
                                'button-dark': themeSelected === MSTeamsThemes.DARK
                            })
                        }
                        style={ props.destination_url.settings.button_settings ? {
                            "--text-color": adjustColor(props.destination_url?.settings?.button_settings?.color, 0),
                            "--background-color": adjustColor(props.destination_url?.settings?.button_settings?.background_color, 0),
                            "--background-color-hover-darken": adjustColor(props.destination_url?.settings?.button_settings?.background_color, -10),
                            "--background-color-active-darken": adjustColor(props.destination_url?.settings?.button_settings?.background_color, -20),
                            "--background-color-hover-lighten": adjustColor(props.destination_url?.settings?.button_settings?.background_color, 20),
                        } as React.CSSProperties : {} }>
                        {props.destination_url.settings.button_settings?.text}
                    </Button> : ''
            }
            </Flex>

            {
                props.background.image?.keep_aspect_ratio ?
                    <img
                        data-testid="keep-aspect-ratio-image"
                        className={'keep-aspect-ratio'}
                        src={props.background.image?.url}
                        alt="background"
                    /> : ''
            }
        </Flex>
    )
}
